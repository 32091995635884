import React, { useState } from "react"
import styles from "./NetRateProviderDetail.styles"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import IconButton from "@material-ui/core/IconButton"
import VisibilityIcon from "@material-ui/icons/VisibilityRounded"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOffRounded"
import { formatPrice, getLocale } from "../../utils/helpers"
import { useTheme } from "@basset-la/themed-components"

export interface Props {
  net_rate_provider: number
  currency: string
}

const NetRateProviderDetail: React.FC<Props> = ({ net_rate_provider, currency }) => {
  const { t, i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)
  const theme = useTheme()

  const [hidden, setHidden] = useState<boolean>(false)

  const handleHide = () => {
    setHidden(true)
  }

  const handleShow = () => {
    setHidden(false)
  }

  return (
    <div className={styles.root}>
      {hidden && (
        <div className={styles.showButtonContainer(theme)}>
          <IconButton className={styles.showIconButton} onClick={handleShow}>
            <VisibilityIcon />
          </IconButton>
        </div>
      )}
      {!hidden && (
        <div className={styles.mainContent}>
          <div className={styles.titleContainer}>
            <span className={styles.title}>{t("NetRateProviderDetail.title")}</span>
            <div className={styles.netRateContainer}>
              <span className={styles.netRateProviderContainer}>
                {formatPrice(net_rate_provider, locale, currency)}
              </span>
              <IconButton className={styles.iconButton} onClick={handleHide}>
                <VisibilityOffIcon />
              </IconButton>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default NetRateProviderDetail
