import * as React from "react"
import TextField from "@basset-la/components-commons/dist/components/TextField"
import styles from "./CustomFieldsContainer.styles"
import { InputLabel } from "@material-ui/core"

const CUSTOM_FIELD_TYPES = {
  STRING: "STRING",
  NUMBER: "NUMBER"
} as const

export type CustomFieldTypes = typeof CUSTOM_FIELD_TYPES[keyof typeof CUSTOM_FIELD_TYPES]

export interface CustomField {
  label: string
  name: string
  type: CustomFieldTypes
  value: string
}

export interface Props {
  onChange: (n: string, v: string) => void
  customFields: CustomField[]
  disabled?: boolean
}

const CustomFieldsContainer = ({ onChange, customFields, disabled }: Props) => {
  const onChangeWithField = (fieldName: string, value: any) => {
    onChange(fieldName, value)
  }

  return (
    <>
      <div className={`${styles.root()}`}>
        {customFields.map(({ label, name, value }) => (
          <div className={`${styles.labelTextFieldContainer()}`}>
            <InputLabel>{label}</InputLabel>
            <TextField
              placeholder=""
              value={value}
              onChange={e => onChangeWithField(name, e.target.value)}
              disabled={disabled}
            />
          </div>
        ))}
      </div>
    </>
  )
}

export default CustomFieldsContainer
