import { css } from "@emotion/css"

export default {
  root: () =>
    css({
      flexDirection: "row",
      gap: "16px",
      display: "flex",
      "> div": {
        flex: "1 1 50%",
        " > label.MuiFormLabel-root": {
          fontFamily: "Roboto",
          fontSize: "14px",
          display: "inline-block",
          fontWeight: 300
        },
        " > div.MuiTextField-root": {
          "> div.MuiInputBase-root": {
            height: "32px",
            borderRadius: "3px"
          }
        }
      }
    }),
  labelTextFieldContainer: () =>
    css({
      display: "flex",
      flexDirection: "column",
      gap: "8px"
    })
}
