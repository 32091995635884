import React, { useState } from "react"
import styles from "./PriceboxWeb.styles"
import { formatPriceWeb, getLocale } from "../../utils/helpers"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import DiscountLabel from "../DiscountLabel/DiscountLabel"
import { useTheme } from "@basset-la/themed-components"
import Divider from "@material-ui/core/Divider"
import Collapse from "@material-ui/core/Collapse"
import ArrowDropDown from "@material-ui/icons/ArrowDropDown"
import ArrowDropUp from "@material-ui/icons/ArrowDropUp"

export interface PriceItem {
  label: string
  value: number
}

export interface Strikethrough {
  total: number
  discount: number
}

interface PriceboxRowProps extends PriceItem {
  total?: boolean
  brandColor?: boolean
}

export interface Props {
  isMobile: boolean
  title?: string
  total: PriceItem
  breakdown: PriceItem[]
  currency: string
  strikethrough?: Strikethrough
  taxesDisclaimers?: string[]
  id?: {
    title?: string
    totalPrice?: string
  }
}

const PriceboxWeb: React.FC<Props> = ({
  isMobile,
  title,
  total,
  currency,
  breakdown,
  strikethrough,
  taxesDisclaimers,
  id
}) => {
  const theme = useTheme()
  const { i18n } = useTranslation(I18N_NS)
  const locale = getLocale(i18n)

  const [collapseOpened, setCollapseOpened] = useState(false)

  const handleToggleCollapse = () => {
    setCollapseOpened(!collapseOpened)
  }

  const priceToString = (value: number): string => {
    return priceFormated(value)
  }

  const priceFormated = (value: number) => {
    return formatPriceWeb(value, locale, currency)
  }

  const formatDecimal = (value: string) => {
    return <div className={styles.price}>{value}</div>
  }

  const PriceboxRow = ({ label, value, total, brandColor }: PriceboxRowProps) => (
    <div className={styles.rows(theme, true, total || false, brandColor)}>
      <span>{label}</span>
      <span id={id?.totalPrice}>{priceToString(value)}</span>
    </div>
  )

  return (
    <div className={styles.root(theme, isMobile)}>
      {!isMobile && (
        <>
          {strikethrough && strikethrough.discount && (
            <div className={styles.OFF}>
              <DiscountLabel value={strikethrough.discount} />
            </div>
          )}

          {title && (
            <div className={styles.bigLabel(theme)}>
              <div>
                <span id={id?.title} className="title">
                  {title}
                </span>
              </div>
              <div>
                <span id={id?.totalPrice} className="priceTotal">
                  {formatDecimal(priceToString(total.value))}
                </span>
              </div>
            </div>
          )}

          <div className={styles.internalPrices}>
            {breakdown.map((item, idx) => {
              return <PriceboxRow key={idx} label={item.label} value={item.value} />
            })}
          </div>

          {!title && (
            <>
              <Divider className={styles.divisor} />
              <PriceboxRow total label={total.label} value={total.value} />

              {strikethrough && strikethrough.total && (
                <div className={styles.strikethrought(theme)}>{priceToString(strikethrough.total)}</div>
              )}
            </>
          )}

          {!!taxesDisclaimers && (
            <>
              <Divider className={styles.divisor} />
              {taxesDisclaimers.map((d: string, idx: number) => (
                <div key={idx} className={styles.rows(theme, false, false)}>
                  <span>{d}</span>
                </div>
              ))}
            </>
          )}
        </>
      )}

      {isMobile && (
        <>
          <Collapse in={collapseOpened}>
            {breakdown.map((item, idx) => {
              return <PriceboxRow key={idx} label={item.label} value={item.value} />
            })}
            {taxesDisclaimers &&
              taxesDisclaimers.map((d: string, idx: number) => {
                return (
                  <div key={idx} className={styles.rows(theme, false, false)}>
                    <span>{d}</span>
                  </div>
                )
              })}
            <Divider className={styles.mobDivider(theme, isMobile)} />
          </Collapse>

          <div className={styles.mobTotalContainer} onClick={handleToggleCollapse}>
            <PriceboxRow total brandColor label={total.label} value={total.value} />
            {strikethrough && strikethrough.total && (
              <div className={styles.strikethrought(theme)}>{priceToString(strikethrough.total)}</div>
            )}

            <div className={styles.mobDividerContainer}>
              <Divider className={styles.mobDivider(theme, isMobile)} />
              <div className={styles.arrow(theme, collapseOpened)}>
                {collapseOpened && <ArrowDropUp />}
                {!collapseOpened && <ArrowDropDown />}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default PriceboxWeb
