import { css } from "@emotion/css"
import { colors } from "../../utils/constants/constants"
import { Theme } from "@basset-la/themed-components"

export default {
  root: css({
    display: "flex",
    flexDirection: "row",
    height: "45px",
    borderRadius: "4px",
    justifyContent: "right",
    padding: "0px",
    maxWidth: "480px",
    width: "100%"
  }),
  mainContent: css({
    display: "flex",
    borderRadius: "4px",
    flexDirection: "column",
    backgroundColor: colors.GREY,
    fontFamily: "Roboto",
    color: colors.WHITE,
    boxSizing: "border-box",
    padding: "4px 8px 4px 8px",
    width: "100%",
    height: "fit-content"
  }),
  titleContainer: css({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    boxSizing: "border-box",
    justifyContent: "space-between"
  }),
  netRateContainer: css({
    justifyContent: "space-between",
    display: "flex",
    height: "15px"
  }),
  netRateProviderContainer: css({
    justifyContent: "space-between",
    width: "fit-content",
    height: "16px",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "0.13px"
  }),
  title: css({
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "0.13px",
    marginRight: "0px"
  }),
  iconButton: css({
    "&.MuiIconButton-root": {
      padding: 0,
      width: "24px",
      height: "24px",
      bottom: "5px",
      " .MuiSvgIcon-root": {
        fontSize: "16px",
        color: colors.WHITE
      }
    }
  }),
  showIconButton: css({
    "&.MuiIconButton-root": {
      padding: 0,
      width: "24px",
      height: "24px",
      " .MuiSvgIcon-root": {
        fontSize: "16px",
        color: colors.GREY
      }
    }
  }),
  showButtonContainer: (t: Theme) =>
    css({
      width: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "4px",
      gap: "8px",
      alignSelf: "flex-end"
    })
}
