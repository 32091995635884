import React, { useEffect, useRef, useState } from "react"
import styles from "./ProductDatePicker.styles"
import { Moment } from "moment"
import RangeDatePicker from "@basset-la/component-date-picker/dist/RangeDatePicker"
import SingleDatePicker from "@basset-la/component-date-picker/dist/SingleDatePicker"
import { useTheme } from "@basset-la/themed-components"
import Popover, { PopoverActions } from "@material-ui/core/Popover"
import CalendarIcon from "@material-ui/icons/TodayRounded"
import AccessTimeIcon from "@material-ui/icons/AccessTime"
import SearchboxTextField from "../SearchboxTextField/SearchboxTextField"
import FullScreenDialog from "../FullScreenDialog/FullScreenDialog"
import Text from "@basset-la/components-commons/dist/components/Text"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import TimeSelect from "./TimeSelect"
import DatePickerMonthElement from "../DatePickerMonthElement/DatePickerMonthElement"

export interface Props {
  startDate: Moment | null
  endDate?: Moment | null
  startDateLabel?: string
  endDateLabel?: string
  useSmallLabels?: boolean
  type: "one-way" | "round-trip"
  startDateError?: string
  endDateError?: string
  minimumNights?: number
  maximumNights?: number
  maxDate?: Moment
  minDate?: Moment
  showTimePicker?: boolean
  startTimeLabel?: string
  endTimeLabel?: string
  defaultTime?: string
  timeInterval?: [string, string]
  startDatePlaceholder?: string
  startTimePlaceholder?: string
  endDatePlaceholder?: string
  endTimePlaceholder?: string
  onChangeDate: (startDate: Moment | null, endDate?: Moment | null) => void
  inputClassName?: string
  showRenderMonthElement?: boolean
}

const DATE_FORMAT = "DD MMM YYYY"
const TIME_FORMAT = "HH:00"

const ProductDatePicker: React.FC<Props> = ({
  startDate,
  endDate,
  startDateLabel,
  endDateLabel,
  useSmallLabels,
  type,
  startDateError,
  endDateError,
  onChangeDate,
  maxDate,
  maximumNights,
  minDate,
  minimumNights,
  inputClassName,
  startTimeLabel,
  endTimeLabel,
  showTimePicker = false,
  defaultTime = "00:00",
  timeInterval = ["00:00", "23:30"],
  startDatePlaceholder,
  startTimePlaceholder,
  endDatePlaceholder,
  endTimePlaceholder,
  showRenderMonthElement = false
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery("(max-width: 1024px)")

  const departureRef = useRef<HTMLDivElement>(null)
  const returnRef = useRef<HTMLDivElement>(null)
  const startTimeRef = useRef<HTMLDivElement>(null)
  const endTimeRef = useRef<HTMLDivElement>(null)

  const [departureDate, setDepartureDate] = useState<Moment | null>(null)
  const [returnDate, setReturnDate] = useState<Moment | null | undefined>(null)
  const [startTime, setStartTime] = useState<string>(defaultTime)
  const [endTime, setEndTime] = useState<string>(defaultTime)
  const [focusedInput, setFocusedInput] = useState<"startDate" | "endDate" | "startTime" | "endTime" | null>(null)

  useEffect(() => {
    setDepartureDate(startDate)
    setReturnDate(endDate)
    setStartTime(startDate ? startDate.format(TIME_FORMAT) : defaultTime)
    setEndTime(endDate ? endDate.format(TIME_FORMAT) : defaultTime)
  }, [endDate, startDate, defaultTime])

  const anchorEl =
    focusedInput === "startDate" ? departureRef!.current : focusedInput === "endDate" ? returnRef!.current : null

  const actions: React.Ref<PopoverActions> = useRef(null)

  useEffect(() => {
    if (actions.current) {
      actions.current.updatePosition()
    }
  }, [anchorEl])

  const handleFocusChange = (focus: "startDate" | "endDate") => {
    setFocusedInput(focus)
  }

  const handleFocusDeparture = () => {
    setFocusedInput("startDate")
  }

  const handleFocusReturn = () => {
    setFocusedInput("endDate")
  }

  const handleFocusStartTime = () => {
    setFocusedInput("startTime")
  }

  const handleFocusEndTime = () => {
    setFocusedInput("endTime")
  }

  const raiseChangeDate = (startDate: Moment | null, sTime: string, endDate: Moment | null, eTime: string) => {
    if (startDate) {
      if (startDate === endDate) {
        endDate = endDate.clone() //Hack when startDate and endDate are the same day, the library is using same moment instance
      }

      const parts = sTime.split(":")
      const h = parseInt(parts[0])
      const m = parseInt(parts[1])
      startDate = startDate.set({ hour: h, minute: m })
    }

    if (endDate) {
      const parts = eTime.split(":")
      const h = parseInt(parts[0])
      const m = parseInt(parts[1])
      endDate = endDate.set({ hour: h, minute: m })
    }

    setDepartureDate(startDate)
    setReturnDate(endDate)

    onChangeDate(startDate, endDate)
  }

  const handleOnDatesChange = (arg: { startDate: Moment | null; endDate: Moment | null }) => {
    const startDate = arg.startDate
    const endDate = focusedInput === "startDate" ? null : arg.endDate
    if (startDate && endDate) {
      setFocusedInput(null)
    }
    raiseChangeDate(startDate, startTime, endDate, endTime)
  }

  const handleSingleRangeFocusChange = (focused: boolean) => {
    setFocusedInput(focused ? "startDate" : null)
  }

  const handleDateChange = (date: Moment | null) => {
    setFocusedInput(null)
    raiseChangeDate(date, startTime, null, endTime)
  }

  const handleTimeChange = (v: string) => {
    if (focusedInput === "startTime") {
      setStartTime(v)
      raiseChangeDate(departureDate, v, returnDate || null, endTime)
    }
    if (focusedInput === "endTime") {
      setEndTime(v)
      raiseChangeDate(departureDate, startTime, returnDate || null, v)
    }
  }

  const handleClose = () => {
    setFocusedInput(null)
  }

  const datePickerComponent = (
    <>
      {type === "round-trip" && (
        <RangeDatePicker
          mobile={isMobile}
          focusedInput={focusedInput === "startDate" || focusedInput === "endDate" ? focusedInput : "startDate"}
          onFocusChange={handleFocusChange}
          onDatesChange={handleOnDatesChange}
          colors={{ primary: theme.colors.brand_primary }}
          selectedFromDate={departureDate}
          selectedToDate={returnDate}
          maxDate={maxDate}
          minDate={minDate}
          maximumNights={maximumNights}
          minimumNights={minimumNights}
          renderMonthElement={showRenderMonthElement ? DatePickerMonthElement : undefined}
        />
      )}
      {type === "one-way" && (
        <SingleDatePicker
          mobile={isMobile}
          onDateChange={handleDateChange}
          onFocusChange={handleSingleRangeFocusChange}
          colors={{ primary: theme.colors.brand_primary }}
          selectedDate={departureDate}
          maxDate={maxDate}
          minDate={minDate}
          renderMonthElement={showRenderMonthElement ? DatePickerMonthElement : undefined}
        />
      )}
    </>
  )

  const timePickerComponent = (
    <TimeSelect
      isMobile={isMobile}
      anchor={focusedInput === "endTime" ? endTimeRef : startTimeRef}
      open={focusedInput === "startTime" || focusedInput === "endTime"}
      timeInterval={timeInterval}
      onChange={handleTimeChange}
      onClose={handleClose}
    />
  )

  return (
    <div className={styles.root(showTimePicker || false)}>
      <div className={styles.fieldContainer(showTimePicker)}>
        <div className={styles.dateField}>
          {!useSmallLabels && (
            <Text className={styles.labelText(theme)} variant="regular" size={14}>
              {startDateLabel}
            </Text>
          )}
          <SearchboxTextField
            className={inputClassName}
            InputLabelProps={{
              className: focusedInput === "startDate" ? "Mui-focused" : ""
            }}
            value={departureDate ? departureDate.format(DATE_FORMAT) : ""}
            placeholder={startDatePlaceholder}
            error={!!startDateError}
            helperText={startDateError}
            leftIcon={<CalendarIcon className={styles.icon(theme)} />}
            InputProps={{
              className: focusedInput === "startDate" ? "Mui-focused" : ""
            }}
            ref={departureRef}
            label={useSmallLabels ? startDateLabel : undefined}
            onClick={handleFocusDeparture}
          />
        </div>
        {showTimePicker && (
          <div className={styles.field}>
            {!useSmallLabels && (
              <Text className={styles.labelText(theme)} variant="regular" size={14}>
                {startTimeLabel}
              </Text>
            )}
            <SearchboxTextField
              className={styles.timePicker}
              value={startTime}
              placeholder={startTimePlaceholder}
              leftIcon={<AccessTimeIcon className={styles.icon(theme)} />}
              label={useSmallLabels ? startTimeLabel : undefined}
              ref={startTimeRef}
              onClick={handleFocusStartTime}
            />
          </div>
        )}
      </div>
      {type === "round-trip" && (
        <div className={styles.fieldContainer(showTimePicker)}>
          <div className={styles.dateField}>
            {!useSmallLabels && (
              <Text className={styles.labelText(theme)} variant="regular" size={14}>
                {endDateLabel}
              </Text>
            )}
            <SearchboxTextField
              className={inputClassName}
              InputLabelProps={{
                className: focusedInput === "endDate" ? "Mui-focused" : ""
              }}
              value={returnDate ? returnDate.format(DATE_FORMAT) : ""}
              placeholder={endDatePlaceholder}
              error={!!endDateError}
              helperText={endDateError}
              leftIcon={<CalendarIcon className={styles.icon(theme)} />}
              InputProps={{
                className: focusedInput === "endDate" ? "Mui-focused" : ""
              }}
              ref={returnRef}
              label={useSmallLabels ? endDateLabel : undefined}
              onClick={handleFocusReturn}
            />
          </div>
          {showTimePicker && (
            <div className={styles.field}>
              {!useSmallLabels && (
                <Text className={styles.labelText(theme)} variant="regular" size={14}>
                  {endTimeLabel}
                </Text>
              )}
              <SearchboxTextField
                className={styles.timePicker}
                value={endTime}
                placeholder={endTimePlaceholder}
                leftIcon={<AccessTimeIcon className={styles.icon(theme)} />}
                label={useSmallLabels ? endTimeLabel : undefined}
                ref={endTimeRef}
                onClick={handleFocusEndTime}
              />
            </div>
          )}
        </div>
      )}
      {!isMobile && (
        <>
          {(focusedInput === "startDate" || focusedInput === "endDate") && (
            <Popover
              open={focusedInput !== null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              action={actions}
              anchorEl={anchorEl}
              onClose={handleClose}
            >
              <div className={styles.popover}>{datePickerComponent}</div>
            </Popover>
          )}
          {(focusedInput === "startTime" || focusedInput === "endTime") && timePickerComponent}
        </>
      )}
      {isMobile && (
        <FullScreenDialog
          open={focusedInput !== null}
          onClose={handleClose}
          toolbarComponent={
            <div className={styles.toolbarTextFieldContainer}>
              {(focusedInput === "startDate" || focusedInput === "endDate") && (
                <>
                  <SearchboxTextField
                    className={styles.toolbarTextField}
                    value={departureDate ? departureDate.format(DATE_FORMAT) : ""}
                    leftIcon={<CalendarIcon className={styles.icon(theme)} />}
                    label={startDateLabel}
                  />
                  {type === "round-trip" && (
                    <SearchboxTextField
                      className={styles.toolbarTextField}
                      value={returnDate ? returnDate.format(DATE_FORMAT) : ""}
                      leftIcon={<CalendarIcon className={styles.icon(theme)} />}
                      label={endDateLabel}
                    />
                  )}
                </>
              )}
              {(focusedInput === "startTime" || focusedInput === "endTime") && (
                <SearchboxTextField
                  className={styles.toolbarTextField}
                  value={focusedInput === "startTime" ? startTime : endTime}
                  leftIcon={<AccessTimeIcon className={styles.icon(theme)} />}
                  label={focusedInput === "startTime" ? startTimeLabel : endTimeLabel}
                />
              )}
            </div>
          }
        >
          {(focusedInput === "startDate" || focusedInput === "endDate") && datePickerComponent}
          {(focusedInput === "startTime" || focusedInput === "endTime") && timePickerComponent}
        </FullScreenDialog>
      )}
    </div>
  )
}

export default ProductDatePicker
