import { css } from "@emotion/css"
import { ColorProps } from "./AbstractDatePicker"

const DEFAULT_COLORS: ColorProps = {
  primary: "#2f64ea"
}

export const datepickerStyle = (colors: ColorProps | undefined) => {
  colors = colors || DEFAULT_COLORS

  return css({
    "&, *": {
      fontFamily: "Roboto",
      boxSizing: "border-box",
      color: "#4a4a4a"
    },
    "& .DayPicker table td": {
      verticalAlign: "middle"
    },
    "& .CalendarMonth_caption": {
      paddingBottom: "50px"
    },
    "& .CalendarMonth_caption>strong": {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "1.5",
      color: "#4a4a4a"
    },
    "& .DayPicker_weekHeader_ul": {
      ">li>small": {
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "1.5",
        color: "#4a4a4a"
      },
      borderBottom: "1px solid #f4f4f4",
      paddingBottom: "8px"
    },
    "& .CalendarMonth_table .CalendarDay__selected": {
      backgroundColor: colors.primary,
      color: "#fff"
    },
    "& .CalendarMonth_table .CalendarDay__selected_span": {
      backgroundColor: `${colors.primary}20`,
      color: "#4a4a4a"
    },
    "& .CalendarMonth_table .CalendarDay__default": {
      border: "none",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "1.33",
      "&.CalendarDay__blocked_out_of_range, &.CalendarDay__blocked_minimum_nights": {
        backgroundColor: "#fff",
        fontWeight: "normal",
        color: "#9b9b9b",
        "&.CalendarDay__hovered_span, &:hover": {
          backgroundColor: "#fff !important",
          color: "#9b9b9b"
        }
      }
    },
    "& .CalendarMonth_table .CalendarDay__default:hover, & .CalendarMonth_table .CalendarDay__hovered_span": {
      backgroundColor: `${colors.primary}40`,
      color: "#4a4a4a"
    },
    "& .DayPickerNavigation_button .DayPickerNavigation_svg__horizontal": {
      fill: colors.primary
    },
    "& .DayPickerNavigation_button:hover": {
      borderColor: `${colors.primary}50`
    }
  })
}
